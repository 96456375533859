import $ from 'jquery';

window.jQuery = $;

import 'what-input';
import './lib/foundation-explicit-pieces';

$(document).ready(function () {

    $(document).foundation();

    var current_uri = window.location.pathname.split("/")[1];

    $(document).on('click', '.main-nav a, .block a[href^="#"], #mobile-animated-menu a', function (event) {

        if (current_uri == '') {
            event.preventDefault();

            var href = $(this).attr("href");
            var hash = href.substr(href.indexOf("#"));
            console.log(hash);

            $('html, body').animate({
                scrollTop: $(hash).offset().top - 100
            }, 500);
        }

    });
});
